<template>
    <v-sheet class="d-flex justify-center">
        <v-sheet class="ma-9 pa-9" v-if="data.signInStep === 'signIn'">
            <v-card width="500" max-width="500" height="700" max-height="700">
                <v-card-text>
                    <v-img
                        src="/ec-parcel-logo-v2.png"
                        :max-width="$vuetify.display.smAndDown ? '150px' : '250px'"
                        class="mx-auto mb-6"
                        contain
                    ></v-img>
                    <h1 class="text-center ma-2 pa-2">Sign In</h1>
                    <div class="text-center mb-2">to continue to ecParcel Customer Web</div>
                    <v-sheet class="ma-3 pa-3"></v-sheet>
                    <v-form ref="inputForm" v-model="data.isValidSignIn">
                        <v-text-field
                            ref="username"
                            v-model="signInRequest.username"
                            :label="formLabelText.username"
                            :rules="emailRules"
                            :disabled="loading"
                        ></v-text-field>
                        <v-text-field
                            v-model="signInRequest.password"
                            :label="formLabelText.password"
                            type="password"
                            :rules="passwordRules"
                            :disabled="loading"
                            @keydown="onKeydownSignIn"
                        ></v-text-field>
                        <v-sheet>
                            <router-link :to="pathForgotPassword">Forgot Password</router-link>
                            <v-btn
                                style="float: right"
                                color="primary"
                                :loading="loading"
                                :disabled="loading || !data.isValidSignIn"
                                @click="onSignInClick"
                            >
                                Sign In
                            </v-btn>
                        </v-sheet>
                    </v-form>
                </v-card-text>
            </v-card>
            <v-sheet class="mt-2 pt-3" width="500" max-width="500">
                <alert-message ref="errorAlert" :message="data.errorAlertMessage"></alert-message>
            </v-sheet>
        </v-sheet>
        <v-sheet class="ma-9 pa-9">
            <CompleteNewPassword
                v-if="data.signInStep === 'completeNewPassword'"
                :user="data.signInUser"
            ></CompleteNewPassword>
        </v-sheet>
    </v-sheet>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { useAuthStore } from '../stores/AuthStore';
import _ from 'lodash';
import { LocalStorageHelper } from '../utils/LocalStorageHelper';
import { ValidationHelper } from '../utils/ValidationHelper';
import { AuthLabelTextHelper } from '../utils/labelTextHelper/AuthLabelTextHelper';
import { PathDefinition } from '../utils/PathDefinition';
import CompleteNewPassword from '../components/CompleteNewPassword.vue';
import AlertMessage from '../components/AlertMessage.vue';

export default defineComponent({
    name: 'SignIn',

    components: {
        AlertMessage,
        CompleteNewPassword
    },

    setup() {
        const authStore = useAuthStore();
        const data = reactive({
            errorAlertMessage: '',
            isValidSignIn: false,
            isValidCompleteNewPassword: false,
            signInStep: 'signIn',
            signInUser: {}
        });
        return {
            authStore,
            data
        };
    },
    data() {
        return {
            loading: false,
            pathForgotPassword: PathDefinition.forgotPassword,
            emailRules: [ValidationHelper.getValidEmailRule(), ValidationHelper.getNotAllowWhitespaceRule()],
            passwordRules: [ValidationHelper.getRequiredRule()],
            formLabelText: AuthLabelTextHelper.getLabelText,
            signInRequest: {
                username: '',
                password: ''
            }
        };
    },
    computed: {
        signedIn() {
            return !_.isEqual(this.authStore.currentUser, LocalStorageHelper.defaultEmptyItemJson);
        }
    },
    async created() {
        if (this.signedIn) {
            await this.$router.push({ path: PathDefinition.account });
        }
    },
    mounted() {
        this.$refs.username.focus();
    },
    methods: {
        async onKeydownSignIn(evt: any) {
            if (evt.key === 'Enter') {
                await this.onSignInClick();
            }
        },
        async onSignInClick() {
            const { valid } = await this.$refs.inputForm.validate();
            if (valid) {
                this.loading = true;
                await this.signIn(this.signInRequest);
                this.loading = false;
            }
        },
        async signIn(request: any) {
            const response = await this.authStore.signIn(request.username, request.password);

            if (response.success) {
                this.data.signInUser = response.signInUser;
                const nextStep: any = _.get(response, 'nextStep', {});
                const signInStep: any = _.get(response, 'nextStep.signInStep', '');
                if (signInStep && signInStep === 'DONE') {
                    console.log('normal sign in');
                    return;
                } else if (signInStep && signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
                    this.data.signInStep = 'completeNewPassword';
                } else {
                    const challengeErrMsg = `other nextStep signInStep: ${signInStep}`;
                    this.data.errorAlertMessage = challengeErrMsg;
                    this.$refs.errorAlert.showAlert();
                }
            } else {
                this.data.errorAlertMessage = response.errors;
                this.$refs.errorAlert.showAlert();
            }
        }
    }
});
</script>

<style>
text-center {
    text-align: center;
}
</style>
